body {
  margin: 0;
  font-family: NeutraText-Book !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 190px 1502px #fff, 1048px 546px #fff, 1513px 561px #fff, 1858px 293px #fff, 1466px 1836px #fff, 1726px 1732px #fff, 712px 809px #fff, 1504px 1659px #fff, 1411px 107px #fff,
    354px 780px #fff, 626px 382px #fff, 1506px 1141px #fff, 136px 208px #fff, 1954px 1359px #fff, 1175px 1120px #fff, 127px 1876px #fff, 470px 1284px #fff, 172px 270px #fff, 242px 835px #fff,
    1746px 1371px #fff, 830px 1677px #fff, 1135px 41px #fff, 1155px 1986px #fff, 313px 1543px #fff, 1691px 1289px #fff, 1632px 428px #fff, 227px 1095px #fff, 351px 757px #fff, 1916px 7px #fff,
    1774px 1515px #fff, 158px 1388px #fff, 363px 352px #fff, 982px 817px #fff, 96px 1076px #fff, 608px 403px #fff, 1517px 880px #fff, 539px 430px #fff, 1468px 180px #fff, 1864px 1667px #fff,
    1505px 1762px #fff, 352px 1490px #fff, 1997px 739px #fff, 314px 604px #fff, 656px 845px #fff, 1807px 217px #fff, 1851px 1254px #fff, 401px 653px #fff, 1918px 78px #fff, 1608px 846px #fff,
    1700px 1762px #fff, 82px 1171px #fff, 1947px 1187px #fff, 1095px 649px #fff, 280px 393px #fff, 394px 681px #fff, 298px 1983px #fff, 1878px 1588px #fff, 1790px 159px #fff, 1283px 1685px #fff,
    387px 1880px #fff, 752px 80px #fff, 1773px 584px #fff, 161px 1031px #fff, 1957px 1960px #fff, 955px 255px #fff, 367px 875px #fff, 1175px 1558px #fff, 982px 1773px #fff, 136px 522px #fff,
    184px 939px #fff, 1765px 253px #fff, 939px 1859px #fff, 472px 1740px #fff, 1342px 1259px #fff, 9px 402px #fff, 188px 1113px #fff, 748px 365px #fff, 88px 1705px #fff, 1385px 386px #fff,
    957px 313px #fff, 1356px 277px #fff, 1889px 1774px #fff, 1937px 967px #fff, 1030px 147px #fff, 1789px 1570px #fff, 1516px 1863px #fff, 964px 321px #fff, 558px 1811px #fff, 1058px 1954px #fff,
    1053px 891px #fff, 592px 49px #fff, 919px 1618px #fff, 1614px 1393px #fff, 293px 1282px #fff, 1152px 156px #fff, 1224px 738px #fff, 1150px 1545px #fff, 87px 1652px #fff, 1880px 15px #fff,
    1474px 1057px #fff, 149px 636px #fff, 1572px 772px #fff, 544px 352px #fff, 918px 368px #fff, 1833px 1941px #fff, 1450px 1700px #fff, 1641px 1190px #fff, 49px 158px #fff, 984px 1886px #fff,
    1650px 254px #fff, 218px 789px #fff, 193px 692px #fff, 1356px 547px #fff, 714px 280px #fff, 825px 1241px #fff, 517px 268px #fff, 853px 1436px #fff, 1250px 540px #fff, 1592px 1215px #fff,
    248px 218px #fff, 745px 1837px #fff, 505px 1526px #fff, 1545px 682px #fff, 1277px 1290px #fff, 1837px 212px #fff, 1293px 14px #fff, 330px 1941px #fff, 1716px 778px #fff, 1674px 965px #fff,
    1319px 1680px #fff, 914px 1729px #fff, 1238px 507px #fff, 1611px 518px #fff, 719px 320px #fff, 70px 1088px #fff, 1191px 794px #fff, 616px 1804px #fff, 415px 1237px #fff, 676px 517px #fff,
    1710px 982px #fff, 449px 1267px #fff, 1715px 1590px #fff, 218px 1892px #fff, 1978px 154px #fff, 1115px 1603px #fff, 1112px 746px #fff, 593px 429px #fff, 364px 1164px #fff, 1835px 1623px #fff,
    1282px 878px #fff, 1822px 318px #fff, 352px 1330px #fff, 53px 1115px #fff, 1396px 1084px #fff, 1762px 342px #fff, 766px 489px #fff, 1481px 1947px #fff, 23px 1617px #fff, 1508px 1075px #fff,
    1402px 1361px #fff, 439px 17px #fff, 445px 490px #fff, 1371px 466px #fff, 1392px 1980px #fff, 1580px 1447px #fff, 1559px 464px #fff, 757px 675px #fff, 1529px 885px #fff, 1575px 209px #fff,
    1019px 1286px #fff, 1057px 1682px #fff, 242px 387px #fff, 1027px 599px #fff, 75px 407px #fff, 986px 1779px #fff, 667px 258px #fff, 896px 1877px #fff, 491px 278px #fff, 661px 1875px #fff,
    272px 1733px #fff, 374px 1488px #fff, 1441px 1389px #fff, 1634px 448px #fff, 679px 1340px #fff, 647px 1288px #fff, 1612px 581px #fff, 1521px 1693px #fff, 258px 1353px #fff, 732px 1456px #fff,
    906px 1985px #fff, 1560px 1126px #fff, 1298px 1865px #fff, 1400px 886px #fff, 1926px 1789px #fff, 977px 1500px #fff, 1760px 612px #fff, 212px 608px #fff, 866px 758px #fff, 1848px 472px #fff,
    801px 1742px #fff, 621px 1640px #fff, 802px 519px #fff, 1607px 932px #fff, 117px 1082px #fff, 26px 1441px #fff, 174px 624px #fff, 1825px 892px #fff, 931px 448px #fff, 1824px 644px #fff,
    421px 1942px #fff, 707px 1615px #fff, 1031px 1039px #fff, 964px 1347px #fff, 34px 680px #fff, 374px 835px #fff, 1269px 863px #fff, 1313px 1959px #fff, 701px 1119px #fff, 1527px 742px #fff,
    1346px 669px #fff, 151px 1410px #fff, 422px 311px #fff, 306px 1561px #fff, 343px 1741px #fff, 219px 648px #fff, 758px 971px #fff, 201px 937px #fff, 1761px 481px #fff, 1959px 1310px #fff,
    1848px 1483px #fff, 654px 995px #fff, 170px 1920px #fff, 536px 1476px #fff, 1260px 1946px #fff, 555px 1660px #fff, 134px 238px #fff, 792px 1090px #fff, 1407px 1644px #fff, 783px 253px #fff,
    1935px 198px #fff, 573px 913px #fff, 724px 795px #fff, 558px 685px #fff, 1128px 1860px #fff, 1911px 850px #fff, 503px 1641px #fff, 797px 309px #fff, 1582px 1343px #fff, 883px 1264px #fff,
    1280px 459px #fff, 1147px 666px #fff, 301px 1190px #fff, 256px 780px #fff, 979px 625px #fff, 281px 239px #fff, 1692px 869px #fff, 456px 195px #fff, 302px 410px #fff, 1005px 5px #fff,
    417px 1389px #fff, 117px 747px #fff, 982px 269px #fff, 1966px 693px #fff, 1899px 1774px #fff, 375px 759px #fff, 601px 1957px #fff, 1559px 969px #fff, 1220px 1927px #fff, 933px 92px #fff,
    126px 348px #fff, 1510px 305px #fff, 491px 621px #fff, 875px 414px #fff, 1788px 307px #fff, 1571px 1300px #fff, 1019px 1163px #fff, 1811px 1670px #fff, 1861px 233px #fff, 232px 1421px #fff,
    750px 1905px #fff, 1176px 234px #fff, 349px 1279px #fff, 1494px 1121px #fff, 819px 903px #fff, 1150px 1912px #fff, 87px 23px #fff, 1365px 231px #fff, 1150px 966px #fff, 1104px 1020px #fff,
    309px 1455px #fff, 776px 1742px #fff, 212px 584px #fff, 1845px 782px #fff, 1318px 1426px #fff, 1557px 559px #fff, 772px 22px #fff, 1181px 1070px #fff, 715px 95px #fff, 1893px 436px #fff,
    1330px 210px #fff, 759px 456px #fff, 149px 1419px #fff, 94px 238px #fff, 1514px 380px #fff, 25px 77px #fff, 20px 307px #fff, 1634px 1863px #fff, 1548px 310px #fff, 1645px 994px #fff,
    665px 1707px #fff, 1928px 369px #fff, 100px 572px #fff, 578px 624px #fff, 1458px 296px #fff, 1984px 1055px #fff, 371px 350px #fff, 430px 37px #fff, 8px 1144px #fff, 1071px 556px #fff,
    133px 817px #fff, 1101px 566px #fff, 594px 364px #fff, 1247px 1094px #fff, 1964px 595px #fff, 1223px 1460px #fff, 1381px 1860px #fff, 471px 310px #fff, 1262px 245px #fff, 1198px 505px #fff,
    1820px 1722px #fff, 1213px 1221px #fff, 996px 1123px #fff, 1575px 480px #fff, 895px 1285px #fff, 1217px 523px #fff, 297px 471px #fff, 212px 100px #fff, 1031px 459px #fff, 171px 1636px #fff,
    1800px 1784px #fff, 151px 725px #fff, 565px 531px #fff, 1637px 1985px #fff, 1168px 601px #fff, 1157px 1561px #fff, 1200px 1163px #fff, 1142px 1853px #fff, 661px 1537px #fff, 577px 427px #fff,
    1314px 100px #fff, 21px 458px #fff, 386px 1992px #fff, 848px 1039px #fff, 207px 1175px #fff, 203px 13px #fff, 1553px 1454px #fff, 994px 1020px #fff, 1317px 877px #fff, 816px 1400px #fff,
    1217px 891px #fff, 1776px 1629px #fff, 338px 1418px #fff, 1673px 1100px #fff, 587px 958px #fff, 19px 203px #fff, 1084px 1617px #fff, 1633px 878px #fff, 1978px 1288px #fff, 148px 1876px #fff,
    1467px 1729px #fff, 1156px 1703px #fff, 45px 1644px #fff, 180px 508px #fff, 459px 482px #fff, 1169px 1099px #fff, 1940px 19px #fff, 614px 1164px #fff, 1272px 1626px #fff, 1048px 305px #fff,
    420px 1204px #fff, 730px 1206px #fff, 95px 1217px #fff, 882px 1554px #fff, 1719px 1829px #fff, 823px 1879px #fff, 1750px 523px #fff, 618px 301px #fff, 1056px 365px #fff, 316px 1492px #fff,
    1109px 102px #fff, 1812px 1837px #fff, 716px 736px #fff, 536px 799px #fff, 40px 1615px #fff, 1032px 998px #fff, 340px 1797px #fff, 1888px 670px #fff, 176px 929px #fff, 637px 1639px #fff,
    1603px 1129px #fff, 997px 626px #fff, 1783px 1837px #fff, 1269px 1651px #fff, 1586px 1716px #fff, 445px 825px #fff, 1593px 969px #fff, 1785px 1580px #fff, 1082px 1501px #fff, 844px 1536px #fff,
    101px 491px #fff, 660px 392px #fff, 1809px 122px #fff, 142px 1942px #fff, 1445px 1387px #fff, 1476px 275px #fff, 1343px 192px #fff, 1872px 1297px #fff, 1256px 826px #fff, 360px 1491px #fff,
    963px 91px #fff, 1263px 1192px #fff, 1572px 731px #fff, 1577px 33px #fff, 62px 433px #fff, 937px 398px #fff, 90px 163px #fff, 1053px 1132px #fff, 1916px 422px #fff, 64px 1695px #fff,
    420px 952px #fff, 194px 1322px #fff, 1791px 1213px #fff, 104px 649px #fff, 459px 535px #fff, 1043px 1196px #fff, 96px 631px #fff, 564px 1217px #fff, 393px 1895px #fff, 979px 1192px #fff,
    414px 1293px #fff, 49px 355px #fff, 590px 1188px #fff, 89px 599px #fff, 324px 1352px #fff, 616px 1138px #fff, 1473px 1333px #fff, 893px 1844px #fff, 1761px 957px #fff, 833px 1247px #fff,
    1713px 1191px #fff, 797px 1081px #fff, 1993px 1795px #fff, 1754px 1775px #fff, 108px 431px #fff, 1291px 133px #fff, 1749px 529px #fff, 1985px 1497px #fff, 1710px 1071px #fff, 1935px 1201px #fff,
    1256px 1249px #fff, 1007px 81px #fff, 1425px 397px #fff, 1580px 530px #fff, 1628px 1910px #fff, 591px 731px #fff, 1494px 939px #fff, 1146px 582px #fff, 1240px 1486px #fff, 1328px 1490px #fff,
    599px 958px #fff, 1910px 1664px #fff, 192px 466px #fff, 22px 497px #fff, 1443px 236px #fff, 822px 1965px #fff, 142px 83px #fff, 289px 73px #fff, 212px 1865px #fff, 1789px 1048px #fff,
    559px 776px #fff, 1412px 784px #fff, 1192px 647px #fff, 1145px 1051px #fff, 909px 309px #fff, 1768px 883px #fff, 441px 1815px #fff, 581px 395px #fff, 1535px 1988px #fff, 1186px 1017px #fff,
    249px 456px #fff, 1108px 1132px #fff, 1844px 435px #fff, 1751px 1105px #fff, 571px 883px #fff, 1223px 1683px #fff, 988px 531px #fff, 529px 1304px #fff, 109px 699px #fff, 455px 1788px #fff,
    1321px 33px #fff, 389px 1219px #fff, 940px 245px #fff, 1814px 682px #fff, 395px 1635px #fff, 1335px 889px #fff, 1046px 1622px #fff, 1827px 1112px #fff, 1327px 1929px #fff, 892px 157px #fff,
    313px 1048px #fff, 1336px 1551px #fff, 590px 1466px #fff, 1702px 1147px #fff, 1505px 1435px #fff, 138px 1892px #fff, 1015px 335px #fff, 1309px 1414px #fff, 1756px 411px #fff, 877px 1294px #fff,
    929px 440px #fff, 1557px 1288px #fff, 659px 737px #fff, 66px 937px #fff, 1391px 389px #fff, 209px 400px #fff, 1059px 1516px #fff, 1594px 252px #fff, 1583px 1954px #fff, 451px 1659px #fff,
    942px 493px #fff, 1288px 292px #fff, 660px 44px #fff, 1080px 1815px #fff, 1722px 1820px #fff, 717px 1192px #fff, 994px 1163px #fff, 338px 1561px #fff, 682px 1321px #fff, 56px 1920px #fff,
    260px 369px #fff, 1816px 1183px #fff, 1151px 1535px #fff, 600px 1797px #fff, 698px 1400px #fff, 839px 441px #fff, 269px 1121px #fff, 64px 20px #fff, 1143px 1112px #fff, 809px 459px #fff,
    1068px 611px #fff, 1052px 889px #fff, 193px 210px #fff, 819px 641px #fff, 442px 1350px #fff, 1190px 1212px #fff, 1425px 743px #fff, 982px 179px #fff, 1275px 21px #fff, 1539px 1655px #fff,
    1830px 140px #fff, 124px 17px #fff, 1278px 565px #fff, 1144px 847px #fff, 1399px 206px #fff, 1020px 1751px #fff, 658px 1740px #fff, 252px 546px #fff, 859px 595px #fff, 819px 1148px #fff,
    1216px 258px #fff, 38px 1533px #fff, 147px 52px #fff, 622px 21px #fff, 330px 1992px #fff, 1566px 533px #fff, 1257px 1442px #fff, 1989px 1057px #fff, 668px 918px #fff, 394px 458px #fff,
    1753px 786px #fff, 130px 1230px #fff, 1529px 1121px #fff, 543px 1429px #fff, 1130px 857px #fff, 1038px 977px #fff, 1872px 1940px #fff, 1370px 1624px #fff, 1459px 626px #fff, 1939px 1014px #fff,
    1359px 1177px #fff, 1704px 1889px #fff, 568px 502px #fff, 1403px 310px #fff, 1960px 511px #fff, 1910px 1656px #fff, 794px 1747px #fff, 170px 1712px #fff, 273px 1351px #fff, 644px 84px #fff,
    116px 1688px #fff, 129px 266px #fff, 1768px 1563px #fff, 803px 155px #fff, 1259px 1456px #fff, 1559px 60px #fff, 1228px 1737px #fff, 357px 983px #fff, 1644px 345px #fff, 1320px 1937px #fff,
    892px 1431px #fff, 1473px 791px #fff, 1882px 136px #fff, 614px 1276px #fff, 1654px 1873px #fff, 1296px 1406px #fff, 635px 1237px #fff, 615px 1155px #fff, 647px 580px #fff, 205px 1215px #fff,
    583px 215px #fff, 515px 1573px #fff, 564px 729px #fff, 1822px 945px #fff, 1738px 981px #fff, 940px 811px #fff, 983px 465px #fff, 403px 764px #fff, 1356px 292px #fff, 716px 1258px #fff,
    1481px 680px #fff, 138px 875px #fff, 147px 26px #fff, 528px 343px #fff, 597px 354px #fff, 866px 872px #fff, 1671px 503px #fff, 1187px 148px #fff, 918px 1451px #fff, 1653px 228px #fff,
    1339px 678px #fff, 327px 356px #fff, 486px 807px #fff, 869px 369px #fff, 606px 1548px #fff, 1027px 485px #fff, 943px 459px #fff, 1538px 1121px #fff, 1504px 1856px #fff, 1075px 657px #fff,
    539px 1661px #fff, 201px 374px #fff, 1084px 1000px #fff, 774px 1256px #fff, 374px 792px #fff, 121px 1993px #fff, 911px 391px #fff, 1530px 372px #fff, 1633px 144px #fff, 773px 698px #fff,
    1053px 1999px #fff, 1275px 1271px #fff, 713px 1169px #fff, 1340px 1826px #fff, 1267px 1895px #fff, 1417px 1476px #fff, 1124px 1404px #fff, 1918px 354px #fff, 1079px 1125px #fff, 1408px 503px #fff,
    227px 115px #fff, 718px 510px #fff, 566px 1783px #fff, 1338px 1236px #fff, 1982px 1651px #fff, 394px 1841px #fff, 1726px 319px #fff, 1032px 620px #fff, 1517px 1206px #fff, 1251px 64px #fff,
    1611px 1768px #fff, 338px 586px #fff, 1729px 1625px #fff, 1566px 566px #fff, 1915px 345px #fff, 159px 405px #fff, 1143px 1965px #fff, 969px 1382px #fff, 502px 1185px #fff, 116px 1412px #fff,
    1886px 413px #fff, 98px 368px #fff, 13px 1356px #fff, 299px 1257px #fff, 1794px 1765px #fff, 1043px 882px #fff, 1647px 70px #fff, 860px 690px #fff, 513px 1953px #fff, 111px 1787px #fff,
    394px 1271px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1023px 1561px #fff, 817px 255px #fff, 1965px 1645px #fff, 1638px 57px #fff, 1630px 1885px #fff, 1642px 1556px #fff, 1279px 1619px #fff, 1612px 1884px #fff, 233px 7px #fff,
    94px 1835px #fff, 132px 1890px #fff, 1569px 170px #fff, 314px 1892px #fff, 1387px 1523px #fff, 881px 1186px #fff, 1927px 37px #fff, 689px 1142px #fff, 1512px 1296px #fff, 1527px 940px #fff,
    1721px 1691px #fff, 273px 1285px #fff, 575px 1136px #fff, 177px 611px #fff, 970px 876px #fff, 1300px 1306px #fff, 1549px 1887px #fff, 1555px 1px #fff, 499px 427px #fff, 1511px 1179px #fff,
    112px 987px #fff, 1142px 771px #fff, 718px 1001px #fff, 303px 1343px #fff, 96px 1563px #fff, 390px 1519px #fff, 1510px 465px #fff, 1807px 179px #fff, 969px 265px #fff, 1653px 553px #fff,
    1498px 1391px #fff, 230px 808px #fff, 582px 1583px #fff, 1290px 1220px #fff, 1821px 391px #fff, 337px 1658px #fff, 996px 58px #fff, 811px 1765px #fff, 335px 1673px #fff, 1173px 640px #fff,
    926px 1866px #fff, 1298px 197px #fff, 498px 446px #fff, 1315px 1321px #fff, 84px 318px #fff, 104px 1978px #fff, 1266px 1940px #fff, 985px 246px #fff, 795px 1708px #fff, 268px 1696px #fff,
    1662px 1211px #fff, 1322px 539px #fff, 358px 1048px #fff, 61px 1864px #fff, 1327px 1583px #fff, 427px 1922px #fff, 1561px 1506px #fff, 377px 46px #fff, 1883px 1880px #fff, 259px 1914px #fff,
    692px 1004px #fff, 1895px 536px #fff, 1785px 1450px #fff, 1555px 1506px #fff, 1319px 1163px #fff, 366px 1370px #fff, 44px 1321px #fff, 255px 1365px #fff, 577px 1092px #fff, 681px 597px #fff,
    565px 800px #fff, 1288px 657px #fff, 92px 1708px #fff, 197px 1236px #fff, 991px 410px #fff, 1651px 1053px #fff, 1091px 1193px #fff, 551px 678px #fff, 1578px 226px #fff, 697px 1997px #fff,
    1150px 1690px #fff, 1017px 1044px #fff, 933px 351px #fff, 1493px 742px #fff, 1623px 1429px #fff, 489px 31px #fff, 1043px 1748px #fff, 64px 1798px #fff, 1329px 1062px #fff, 1054px 597px #fff,
    85px 1627px #fff, 165px 885px #fff, 1475px 915px #fff, 1523px 202px #fff, 1704px 232px #fff, 628px 1811px #fff, 1350px 694px #fff, 1463px 392px #fff, 1592px 1624px #fff, 1821px 1009px #fff,
    160px 1061px #fff, 1299px 1978px #fff, 1556px 1264px #fff, 1613px 334px #fff, 11px 1428px #fff, 1180px 933px #fff, 1491px 1225px #fff, 1885px 1167px #fff, 418px 1358px #fff, 1073px 1888px #fff,
    1798px 1131px #fff, 1077px 741px #fff, 365px 1179px #fff, 103px 525px #fff, 975px 1626px #fff, 1420px 1927px #fff, 813px 1583px #fff, 1345px 144px #fff, 659px 268px #fff, 374px 1780px #fff,
    437px 1735px #fff, 1415px 1385px #fff, 380px 278px #fff, 1453px 1596px #fff, 796px 1111px #fff, 1002px 1199px #fff, 68px 979px #fff, 1580px 1644px #fff, 812px 1045px #fff, 1890px 221px #fff,
    459px 1555px #fff, 1190px 980px #fff, 1169px 1492px #fff, 1797px 693px #fff, 1848px 490px #fff, 1810px 370px #fff, 1021px 677px #fff, 1675px 1598px #fff, 281px 369px #fff, 347px 302px #fff,
    296px 603px #fff, 347px 874px #fff, 336px 209px #fff, 932px 526px #fff, 694px 1145px #fff, 127px 1375px #fff, 674px 868px #fff, 732px 493px #fff, 1699px 273px #fff, 1985px 1810px #fff,
    180px 787px #fff, 269px 1290px #fff, 886px 1705px #fff, 1985px 1905px #fff, 1900px 358px #fff, 1511px 1307px #fff, 441px 1719px #fff, 1967px 1631px #fff, 608px 700px #fff, 1662px 1459px #fff,
    576px 1430px #fff, 225px 784px #fff, 1936px 114px #fff, 1205px 1741px #fff, 105px 1857px #fff, 623px 284px #fff, 1419px 1272px #fff, 719px 1859px #fff, 1767px 588px #fff, 1414px 1293px #fff,
    1904px 1901px #fff, 1439px 887px #fff, 281px 266px #fff, 1301px 508px #fff, 586px 140px #fff, 693px 1797px #fff, 813px 1511px #fff, 274px 860px #fff, 603px 1525px #fff, 664px 1418px #fff,
    1853px 209px #fff, 1021px 1189px #fff, 603px 1824px #fff, 389px 1159px #fff, 961px 1710px #fff, 632px 643px #fff, 1648px 1501px #fff, 1674px 912px #fff, 1936px 511px #fff, 1052px 752px #fff,
    1902px 1471px #fff;
}
#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 371px 1473px #fff, 873px 500px #fff, 1470px 93px #fff, 189px 1447px #fff, 1882px 842px #fff, 373px 1934px #fff, 1943px 825px #fff, 458px 1677px #fff, 173px 557px #fff, 922px 1490px #fff,
    1227px 1296px #fff, 736px 1137px #fff, 501px 926px #fff, 872px 741px #fff, 1799px 1121px #fff, 1885px 325px #fff, 524px 1501px #fff, 923px 1589px #fff, 867px 610px #fff, 1477px 681px #fff,
    746px 1704px #fff, 1181px 748px #fff, 1554px 325px #fff, 710px 47px #fff, 74px 768px #fff, 1880px 1629px #fff, 576px 375px #fff, 1792px 1785px #fff, 1579px 1358px #fff, 573px 1620px #fff,
    632px 218px #fff, 1930px 343px #fff, 352px 28px #fff, 909px 1636px #fff, 1521px 715px #fff, 1787px 1200px #fff, 441px 1444px #fff, 154px 1122px #fff, 638px 234px #fff, 843px 707px #fff,
    639px 1126px #fff, 1581px 1277px #fff, 741px 1839px #fff, 628px 433px #fff, 595px 1012px #fff, 559px 707px #fff, 655px 403px #fff, 1609px 1165px #fff, 1875px 140px #fff, 514px 579px #fff,
    73px 1172px #fff, 424px 1271px #fff, 495px 1808px #fff, 1676px 1924px #fff, 1783px 430px #fff, 233px 353px #fff, 1657px 902px #fff, 1215px 461px #fff, 837px 1185px #fff, 615px 871px #fff,
    1709px 855px #fff, 178px 1986px #fff, 778px 476px #fff, 1737px 584px #fff, 1199px 21px #fff, 438px 1438px #fff, 1494px 1322px #fff, 420px 593px #fff, 417px 968px #fff, 1188px 1995px #fff,
    1148px 922px #fff, 1463px 1049px #fff, 196px 454px #fff, 256px 1507px #fff, 62px 1963px #fff, 1558px 787px #fff, 150px 65px #fff, 981px 617px #fff, 1682px 442px #fff, 1467px 454px #fff,
    1064px 1050px #fff, 308px 1135px #fff, 1596px 281px #fff, 1733px 1635px #fff, 721px 1977px #fff, 540px 581px #fff, 999px 1743px #fff, 1256px 397px #fff, 1102px 1044px #fff, 1990px 853px #fff,
    1278px 781px #fff, 1923px 478px #fff, 782px 173px #fff, 761px 556px #fff, 1416px 669px #fff, 173px 1880px #fff, 1594px 568px #fff, 845px 1213px #fff, 72px 1303px #fff, 1165px 194px #fff;
}

@font-face {
  font-family: "NeutraText-Book";
  src: url("./assets/fonts/Neutraface/NeutraText-Book.eot") format("embedded-opentype"), url("./assets/fonts/Neutraface/NeutraText-Book.woff2") format("woff2"),
    url("./assets/fonts/Neutraface/NeutraText-Book.ttf") format("truetype"), url("./assets/fonts/Neutraface/NeutraText-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.MuiSnackbarContent-message {
  color: white;
}
